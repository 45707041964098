import { Position, PositionRateShiftResult, ValidPosition } from '@bakerweb/models';
import { convertSecClass, fCurrency, fDateStandard, fPercent } from '@bakerweb/utils';
import { Theme } from '@mui/material/styles';
import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

interface SecurityDetailsDrawerProps {
  open: boolean;
  onClose: VoidFunction;
  data: any;
}

export function SecurityDetailsDrawer({ open, onClose, data }: SecurityDetailsDrawerProps) {
  const floatSecClasses = [9, 10, 11, 12, 14, 16, 18, 20, 21, 22, 26, 31, 41];
  const shouldRenderFloatingInfo = floatSecClasses.includes(data?.secClass);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: '100%', md: 900 },
          padding: 2,
          backgroundColor: (theme) => theme.palette.background.paper
        }
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: '#fff',
          zIndex: 2,
          padding: 2,
          borderBottom: '1px solid #ddd'
        }}
      >
        <Stack>
          <Typography variant="h5">{data?.cusip}</Typography>
          <Typography variant="subtitle2">{data?.description || 'Security Details'}</Typography>
        </Stack>

        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={accordionSummaryStyles}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Bond Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>CUSIP</b>
                </TableCell>
                <TableCell>{data?.cusip}</TableCell>
                <TableCell>
                  <b>Moody</b>
                </TableCell>
                <TableCell>{data?.rating?.moodyRating || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Description</b>
                </TableCell>
                <TableCell>{data?.description}</TableCell>
                <TableCell>
                  <b>S&P</b>
                </TableCell>
                <TableCell>{data?.rating?.spRating || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Type</b>
                </TableCell>
                <TableCell>{convertSecClass(data?.secClass)}</TableCell>
                <TableCell>
                  <b>CPN</b>
                </TableCell>
                <TableCell>{data?.coupon}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Maturity Date</b>
                </TableCell>
                <TableCell>{fDateStandard(data?.maturityDate)}</TableCell>
                <TableCell>
                  <b>Call Date</b>
                </TableCell>
                <TableCell>{data?.callDate || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Purchase Date</b>
                </TableCell>
                <TableCell>{fDateStandard(data?.purchaseDate)}</TableCell>
                <TableCell>
                  <b>Purchase Price</b>
                </TableCell>
                <TableCell>{fCurrency(data?.purchasePrice)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={accordionSummaryStyles}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Valuation Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Original Face</b>
                </TableCell>
                <TableCell>{fCurrency(data?.origFace)}</TableCell>
                <TableCell>
                  <b>Book Value</b>
                </TableCell>
                <TableCell>{fCurrency(data?.bookValue)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Pledged Amount</b>
                </TableCell>
                <TableCell>{fCurrency(data?.safeKeepingPledgedSum)}</TableCell>
                <TableCell>
                  <b>Market Value</b>
                </TableCell>
                <TableCell>{fCurrency(data?.marketValue)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Current Face</b>
                </TableCell>
                <TableCell>{fCurrency(data?.currFace)}</TableCell>
                <TableCell>
                  <b>Gain/Loss</b>
                </TableCell>
                <TableCell>{fCurrency(data?.gainLoss)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Market Price</b>
                </TableCell>
                <TableCell colSpan={3}>{fCurrency(data?.marketPrice)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      {shouldRenderFloatingInfo && (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={accordionSummaryStyles}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Floating Info
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>CPN Formula</b>
                  </TableCell>
                  <TableCell>{data?.cpnFormula || 'N/A'}</TableCell>
                  <TableCell>
                    <b>Index Rate</b>
                  </TableCell>
                  <TableCell>{data?.indexRate || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Index + Margin</b>
                  </TableCell>
                  <TableCell>{data?.indexMargin || 'N/A'}</TableCell>
                  <TableCell>
                    <b>Reset</b>
                  </TableCell>
                  <TableCell>{data?.resetFrequency || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Next Reset</b>
                  </TableCell>
                  <TableCell>{fDateStandard(data?.nextResetDate)}</TableCell>
                  <TableCell>
                    <b>Periodic Collar</b>
                  </TableCell>
                  <TableCell>{data?.periodicCollar}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Cap</b>
                  </TableCell>
                  <TableCell>{data?.lifeCap}</TableCell>
                  <TableCell>
                    <b>Floor</b>
                  </TableCell>
                  <TableCell>{data?.lifeFloor}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>BEEM</b>
                  </TableCell>
                  <TableCell colSpan={3}>{data?.bookBEEM}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={accordionSummaryStyles}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Yield Analysis
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <Table size="small" sx={{ border: '1px solid #ddd', marginBottom: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}></TableCell>
                  <TableCell align="right" sx={headerStyle}>
                    Acctg TE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={cellStyle}>
                    <b>Yield</b>
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.acctgYield_TE || 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={cellStyle}>
                    <b>Avg Life</b>
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.acctgAvgLife || 'N/A'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Table size="small" sx={{ border: '1px solid #ddd', marginBottom: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}></TableCell>
                  <TableCell align="right" sx={headerStyle}>
                    Proj TE
                  </TableCell>
                  <TableCell align="right" sx={headerStyle}>
                    Mrk TE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={cellStyle}>
                    <b>YTW</b>
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.ytw || 'N/A'}
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.ytw || 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={cellStyle}>
                    <b>Avg Life</b>
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.projAvgLife || 'N/A'}
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.mrkAvgLife || 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={cellStyle}>
                    <b>YTM</b>
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.ytm || 'N/A'}
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.ytm || 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={cellStyle}>
                    <b>YTC</b>
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.ytc || 'N/A'}
                  </TableCell>
                  <TableCell align="right" sx={cellStyle}>
                    {data?.ytc || 'N/A'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table size="small" sx={{ maxWidth: '300px' }}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Acctg Yld</b>
                  </TableCell>
                  <TableCell>{data?.acctgYieldTaxFree || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Eff Dur</b>
                  </TableCell>
                  <TableCell>{data?.acctgDur || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Proj Yld</b>
                  </TableCell>
                  <TableCell>{data?.bookYield || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Eff Convx</b>
                  </TableCell>
                  <TableCell>{data?.effConv || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Mrk Yld</b>
                  </TableCell>
                  <TableCell>{data?.marketYieldTaxFree || 'N/A'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={accordionSummaryStyles}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Scenario Analysis
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Table size="small" sx={{ border: '1px solid #ddd' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableCellStyle}></TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>
                    -300
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>
                    -200
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>
                    -100
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>
                    0
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>
                    100
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>
                    200
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #ddd', ...tableCellStyle }}>
                    300
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>PxVol</TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.marketPriceVol8}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.marketPriceVol7}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.marketPriceVol6}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.marketPriceVol4}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.marketPriceVol2}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.marketPriceVol1}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.marketPriceVol0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>Avg Life</TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.avgLife8}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.avgLife7}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.avgLife6}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.avgLife4}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.avgLife2}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.avgLife1}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #ddd' }}>
                    {data?.avgLife0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Drawer>
  );
}

const accordionSummaryStyles = {
  backgroundColor: (theme: Theme) => theme.palette.primary.main,
  color: 'white',
  '&.Mui-expanded': {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: (theme: Theme) => theme.palette.primary.dark
  },
  '& .MuiAccordionSummary-content': { margin: 0 }
};

const tableCellStyle = {
  boxShadow: 'none  !important',
  borderRadius: '0 !important'
};

const headerStyle = {
  fontWeight: 'bold',
  padding: '8px',
  boxShadow: 'none  !important',
  borderRadius: '0 !important',
  border: '1px solid #ddd',
  backgroundColor: '#f5f5f5'
};

const cellStyle = {
  padding: '8px',
  border: '1px solid #ddd',
  fontSize: '0.875rem'
};
