import { gql } from '@apollo/client';

export const GET_RATE_SHIFTS = gql`
  query PositionRateShiftResults($skip: Int, $take: Int, $positionIds: [String!]) {
    positionRateShiftResults(skip: $skip, take: $take, positionIds: $positionIds) {
      positionId
      portfolioId
      swapPortfolioId
      asOfDate
      marketPrice40
      marketPrice0
      marketPrice1
      marketPrice2
      marketPrice3
      marketPrice5
      marketPrice6
      marketPrice7
      marketPrice8
      marketPrice41
      marketPriceVol40
      marketPriceVol0
      marketPriceVol1
      marketPriceVol2
      marketPriceVol3
      marketPriceVol5
      marketPriceVol6
      marketPriceVol7
      marketPriceVol8
      marketPriceVol41
      avgLife40
      avgLife0
      avgLife1
      avgLife2
      avgLife3
      avgLife4
      avgLife4_Prior
      avgLife5
      avgLife6
      avgLife7
      avgLife8
      avgLife41
      avgLife20
      avgLife21
      avgLife22
      avgLife23
      avgLife24
      bookYieldTE40
      bookYieldTE0
      bookYieldTE1
      bookYieldTE2
      bookYieldTE3
      bookYieldTE41
      bookYieldTE5
      bookYieldTE6
      bookYieldTE7
      bookYieldTE8
      prepaySpeedUsing40
      prepaySpeedUsing0
      prepaySpeedUsing1
      prepaySpeedUsing2
      prepaySpeedUsing3
      prepaySpeedUsing4
      prepaySpeedUsing5
      prepaySpeedUsing6
      prepaySpeedUsing7
      prepaySpeedUsing8
      prepaySpeedUsing41
      prepaySpeedUsing20
      prepaySpeedUsing21
      prepaySpeedUsing22
      prepaySpeedUsing23
      prepaySpeedUsing24
      pricedTo40
      pricedTo0
      pricedTo1
      pricedTo2
      pricedTo3
      pricedTo4
      pricedTo5
      pricedTo6
      pricedTo7
      pricedTo8
      pricedTo41
      pricedToDate40
      pricedToDate0
      pricedToDate1
      pricedToDate2
      pricedToDate3
      pricedToDate4
      pricedToDate5
      pricedToDate6
      pricedToDate7
      pricedToDate8
      pricedToDate41
      effConvex4
      effDur4
    }
  }
`;
