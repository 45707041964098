import { useCallback, useState } from 'react';
import { clientHttp } from '../client.http';

export const useS3FileDownload = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown[]>([]);

  const getFile = useCallback(async (path: string, fileType: string) => {
    setLoading(true);
    setError([]);

    try {
      const urlFragment = `/object/download/${encodeURIComponent(path)}`;
      const response = await clientHttp(urlFragment, {
        method: 'GET',
        responseType: 'json' // DO NOT CHANGE THIS OR YOUR FAMILY WILL BE CURSED FOR GENERATIONS
      });

      console.log('Response:', response);

      // Extract content type and data from the response
      const contentType: string = (() => {
        switch (fileType) {
          case 'pdf':
            return 'application/pdf';
          default:
            return response.ContentType || 'application/octet-stream';
        }
      })();
      const data = response.Body.data;
      const blob = new Blob([new Uint8Array(data)], { type: contentType });
      return { success: blob, contentType, errors: [] };
    } catch (error) {
      setError([error]);
      console.error('Error:', error);
      return { success: null, contentType: null, errors: [error] };
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    error,
    getFile
  };
};
