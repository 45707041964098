import { useGetSkInstitutions, useUpdateSafeKeepingPositions } from '@bakerweb/client-services';
import { selectedPortfolioState } from '@bakerweb/client-state';
import { Pledge, SafeKeepingCode, SafeKeepingPosition, ValidPosition } from '@bakerweb/models';
import { fCurrency, squishSafeKeepingPositions } from '@bakerweb/utils';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  DrawerProps,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { Iconify } from '../iconify/Iconify';
import { Scrollbar } from '../Scrollbar';

interface Props extends DrawerProps {
  positions: ValidPosition[];
  pledgeCodes: Pledge[];
  onClose: VoidFunction;
  filteredPledgeCode: string | null;
  asOfDate: string | null;
  refetchPositions: () => void;
  closeAndClearPositions: () => void;
  showRequestUpdatedBbaPacketButton: () => void;
  isUpdatingPastPositions: boolean;
}

interface DifferencesMap {
  [positionId: string]: number;
}

export function PledgingMultiFlyoutDrawer({
  positions,
  asOfDate,
  pledgeCodes,
  refetchPositions,
  closeAndClearPositions,
  filteredPledgeCode,
  open,
  onClose,
  showRequestUpdatedBbaPacketButton,
  isUpdatingPastPositions,
  ...other
}: Props) {
  const theme = useTheme();
  const [toSkCode, setToSkCode] = useState<SafeKeepingCode | null>(null);
  const [toPledgeCode, setToPledgeCode] = useState<Pledge | null>(null);
  const [bulkPledgeAcknowledged, setBulkPledgeAcknowledged] = useState(false);
  const [bulkSkAcknowledged, setBulkSkAcknowledged] = useState(false);
  const [releaseAllAcknowledged, setReleaseAllAcknowledged] = useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');
  const { updateSafeKeepingPositions } = useUpdateSafeKeepingPositions();
  const portfolio = useRecoilValue(selectedPortfolioState);
  const { safeKeepingInstitutions } = useGetSkInstitutions();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [safeKeepingChangePreview, setSafeKeepingChangePreview] = useState<any[]>([]);

  const { totalCurrFace, totalOrigFace, totalPledgedAmount } = useMemo(() => {
    let totalCurrFace = 0;
    let totalOrigFace = 0;
    let totalPledgedAmount = 0;

    positions.forEach((position) => {
      totalCurrFace += position.currFaceEnding;
      totalOrigFace += position.origFace;
      totalPledgedAmount += position.safeKeepingPledgedSum;
    });

    return { totalCurrFace, totalOrigFace, totalPledgedAmount };
  }, [positions]);

  const calculatePledgedDifferences = () => {
    const differencesMap: DifferencesMap = {};
    positions.forEach((position) => {
      const sumPledgedAmount = position.safeKeepingPositions.reduce(
        (sum: number, safeKeepingPosition: SafeKeepingPosition) => {
          return safeKeepingPosition.pledgeCode !== '-1' ? sum + safeKeepingPosition.pledgedAmount : sum;
        },
        0
      );

      const difference = position.origFace - sumPledgedAmount;
      differencesMap[position.positionId] = difference;
    });
    return differencesMap;
  };

  const differencesMap = calculatePledgedDifferences();
  const saveBulkPledge = async () => {
    if (bulkPledgeAcknowledged && toPledgeCode) {
      try {
        const safeKeepingPositionUpdateDtos = positions.map((position: ValidPosition) => {
          const difference = differencesMap[position.positionId];
          const currentPledgedPositions = position.safeKeepingPositions
            .filter((skp: SafeKeepingPosition) => skp.pledgeCode !== '-1')
            .map((position: SafeKeepingPosition) => ({
              portfolioId: portfolio?.portfolioId,
              asOfDate,
              positionMasterId: position.positionMasterId,
              safekeepingCode: position.safekeepingCode,
              pledgeCode: position.pledgeCode,
              pledgedAmount: position.pledgedAmount
            }));
          const newPledgedPosition = {
            portfolioId: portfolio?.portfolioId,
            asOfDate,
            positionMasterId: position.positionMasterId,
            safekeepingCode: position.safeKeepingPositions[0].safekeepingCode,
            pledgeCode: toPledgeCode.pledgeCode,
            pledgedAmount: difference
          };
          if (difference > 0) {
            currentPledgedPositions.push(newPledgedPosition);
          }
          const consolidatedPositions = squishSafeKeepingPositions(currentPledgedPositions);
          return {
            positionMasterId: position.positionMasterId,
            asOfDate,
            portfolioId: portfolio?.portfolioId,
            safeKeepingPositions: consolidatedPositions
          };
        });
        await updateSafeKeepingPositions(safeKeepingPositionUpdateDtos);
        closeAndClearPositions();
        await refetchPositions();
      } catch (error) {
        console.error('Error updating pledges:', error);
      } finally {
        setBulkPledgeAcknowledged(false);
        setToPledgeCode(null);
        if (isUpdatingPastPositions) {
          showRequestUpdatedBbaPacketButton();
        }
      }
    } else {
      toast.error('Please select a safekeeping code, pledgee, and acknowledge the change to all CUSIPs.');
    }
  };

  const confirmBulkSkChange = async () => {
    if (bulkSkAcknowledged && toSkCode) {
      try {
        const safeKeepingPositionUpdateDtos = positions.map((position: ValidPosition) => {
          const currentPledgedPositions = position.safeKeepingPositions.map((position: SafeKeepingPosition) => ({
            portfolioId: portfolio?.portfolioId,
            asOfDate,
            positionMasterId: position.positionMasterId,
            safekeepingCode: toSkCode.safeKeepingCode,
            pledgeCode: position.pledgeCode,
            pledgedAmount: position.pledgedAmount
          }));
          const consolidatedPositions = squishSafeKeepingPositions(currentPledgedPositions);
          return {
            positionMasterId: position.positionMasterId,
            asOfDate,
            safeKeepingPositions: consolidatedPositions
          };
        });
        // Populate confirm modal
        const preview = positions.map((position, index) => ({
          cusip: position.cusip,
          before: position.safeKeepingPositions,
          after: safeKeepingPositionUpdateDtos[index].safeKeepingPositions
        }));

        setSafeKeepingChangePreview(preview);
        setConfirmationModalOpen(true);
      } catch (error) {
        console.error('Error preparing safekeeping changes:', error);
      }
    } else {
      toast.error('Please select a safekeeping location and acknowledge the change to all CUSIPs.');
    }
  };

  const saveBulkSkChange = async () => {
    try {
      const safeKeepingPositionUpdateDtos = safeKeepingChangePreview.map((item) => {
        const positionMasterId = item.after[0]?.positionMasterId;
        const portfolioId = portfolio?.portfolioId;
        const asOfDate = item.after[0]?.asOfDate;

        const safeKeepingPositions = item.after.map((afterPosition: any) => ({
          portfolioId: portfolio?.portfolioId,
          asOfDate: afterPosition.asOfDate,
          positionMasterId: afterPosition.positionMasterId,
          safekeepingCode: afterPosition.safekeepingCode,
          pledgeCode: afterPosition.pledgeCode,
          pledgedAmount: afterPosition.pledgedAmount
        }));

        return {
          portfolioId,
          positionMasterId,
          asOfDate,
          safeKeepingPositions
        };
      });

      await updateSafeKeepingPositions(safeKeepingPositionUpdateDtos);
      closeAndClearPositions();
      await refetchPositions();
    } catch (error) {
      console.error('Error updating safekeeping positions:', error);
    } finally {
      setConfirmationModalOpen(false);
      setBulkSkAcknowledged(false);
      setToSkCode(null);
      if (isUpdatingPastPositions) {
        showRequestUpdatedBbaPacketButton();
      }
    }
  };

  const saveBulkRelease = async () => {
    if (releaseAllAcknowledged) {
      try {
        const safeKeepingPositionUpdateDtos = positions.map((position) => {
          const uniqueSafeKeepingPositions = position.safeKeepingPositions.map((skp: SafeKeepingPosition) => ({
            portfolioId: portfolio?.portfolioId,
            asOfDate,
            positionMasterId: position.positionMasterId,
            safekeepingCode: skp.safekeepingCode,
            pledgeCode:
              (filteredPledgeCode && skp.pledgeCode === filteredPledgeCode) || !filteredPledgeCode
                ? '-1'
                : skp.pledgeCode, // Code for Not Pledged
            pledgedAmount: skp.pledgedAmount
          }));
          const consolidatedPositions = squishSafeKeepingPositions(uniqueSafeKeepingPositions);
          return {
            positionMasterId: position.positionMasterId,
            asOfDate,
            portfolioId: portfolio?.portfolioId,
            safeKeepingPositions: consolidatedPositions
          };
        });
        await updateSafeKeepingPositions(safeKeepingPositionUpdateDtos);
        await refetchPositions();
        setReleaseAllAcknowledged(false);
      } catch (error) {
        console.error('Error releasing pledges:', error);
      } finally {
        if (isUpdatingPastPositions) {
          showRequestUpdatedBbaPacketButton();
        }
      }
    } else {
      toast.error('Please acknowledge the release of all pledges.');
    }
  };

  const handleBulkPledgeAcknowledged = () => {
    setBulkPledgeAcknowledged(!bulkPledgeAcknowledged);
  };

  const handleBulkSkAcknowledged = () => {
    setBulkSkAcknowledged(!bulkSkAcknowledged);
  };

  const handleReleaseAllAcknowledged = () => {
    setReleaseAllAcknowledged(!releaseAllAcknowledged);
  };

  const getOptionLabel = useCallback((option: SafeKeepingCode) => {
    return `${option.safeKeepingName} (${option.safeKeepingCode})`;
  }, []);

  const safeKeepingCodeToNameMap = useMemo(() => {
    const map: Record<string, string> = {};
    safeKeepingInstitutions.forEach((institution) => {
      map[institution.safeKeepingCode] = institution.safeKeepingName;
    });
    return map;
  }, [safeKeepingInstitutions]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      BackdropProps={{
        invisible: false
      }}
      PaperProps={{
        sx: { width: isMobile ? '100%' : '900px' }
      }}
      {...other}
    >
      <Scrollbar sx={{ height: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
          <Typography variant="h6"> Manage CUSIPs </Typography>
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack spacing={2.5} justifyContent="center" sx={{ p: 2.5 }}>
          <Accordion sx={{ m: '-15px !important' }} disableGutters square elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h4" color="primary">
                {positions.length} CUSIPs Selected
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {positions.map((pos) => {
                return <Typography key={pos.positionMasterId}>{pos.cusip}</Typography>;
              })}
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                Selected Original Face
              </Typography>
              <Typography variant="h4" color="success.dark">
                {fCurrency(totalOrigFace)}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                Selected Current Face
              </Typography>
              <Typography variant="h4" color="success.dark">
                {fCurrency(totalCurrFace)}
              </Typography>
            </Stack>

            <Stack direction="column">
              <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                Selected Pledged Amount
              </Typography>
              <Typography variant="h4" color="success.dark">
                {fCurrency(totalPledgedAmount)}
              </Typography>
            </Stack>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Stack direction="row" justifyContent="space-between" sx={{ mb: 2.5 }}>
                <Typography variant="subtitle2"> Update Pledging for Selected Bonds </Typography>
              </Stack>
              <Stack spacing={2} direction="column" alignItems={{ xs: 'flex-start', md: 'center' }}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="toPledgeCode"
                    options={pledgeCodes}
                    getOptionLabel={(option) => option.pledgeeName}
                    value={toPledgeCode}
                    onChange={(event, newValue) => {
                      if (newValue && 'recId' in newValue) {
                        setToPledgeCode(newValue);
                      } else {
                        setToPledgeCode(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} label="Choose Pledgee" />}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox checked={bulkPledgeAcknowledged} onChange={handleBulkPledgeAcknowledged} />}
                    label="I am aware this change will affect all selected CUSIPs."
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <Button
                    fullWidth
                    disabled={!bulkPledgeAcknowledged}
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon="eva:checkmark-circle-outline" />}
                    onClick={saveBulkPledge}
                  >
                    Save All Pledgee Changes
                  </Button>
                </FormControl>
              </Stack>
            </Stack>
            <Stack direction="column">
              <Stack direction="row" justifyContent="space-between" sx={{ mb: 2.5 }}>
                <Typography variant="subtitle2"> Update Safekeeping for Selected Bonds </Typography>
              </Stack>
              <Stack spacing={2} direction="column" alignItems={{ xs: 'flex-start', md: 'center' }}>
                <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                  <Autocomplete
                    id="toSkCode"
                    options={safeKeepingInstitutions}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={(option, value) => option.recId === value.recId}
                    value={toSkCode}
                    onChange={(event, newValue) => {
                      setToSkCode(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Choose New Safekeeping" />}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox checked={bulkSkAcknowledged} onChange={handleBulkSkAcknowledged} />}
                    label="I am aware this change will affect all selected CUSIPs."
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <Button
                    fullWidth
                    disabled={!bulkSkAcknowledged}
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon="eva:checkmark-circle-outline" />}
                    onClick={confirmBulkSkChange}
                  >
                    Save All SK Changes
                  </Button>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Stack>
            <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="error">
                Release All Pledges For Selected CUSIPS
              </Typography>
            </Stack>
            <Stack spacing={2} direction="column" alignItems={{ xs: 'flex-start', md: 'center' }}>
              <FormControl fullWidth>
                <FormControlLabel
                  color="error"
                  control={
                    <Checkbox color="error" checked={releaseAllAcknowledged} onChange={handleReleaseAllAcknowledged} />
                  }
                  label="I am aware this change will affect all selected CUSIPs."
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <Button
                  fullWidth
                  disabled={!releaseAllAcknowledged}
                  variant="contained"
                  color="error"
                  startIcon={<Iconify icon="eva:alert-circle-outline" />}
                  onClick={saveBulkRelease}
                >
                  Release All Pledges
                </Button>
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
      </Scrollbar>
      <Dialog open={confirmationModalOpen} onClose={() => setConfirmationModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Confirm Safekeeping Changes</DialogTitle>
        <DialogContent sx={{ mt: 2.5 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <strong>CUSIP</strong>
                  </TableCell>
                  <TableCell align="left">
                    <strong>Affected Pledges</strong>
                  </TableCell>
                  <TableCell align="left">
                    <strong>Amount</strong>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: theme.palette.error.dark
                    }}
                  >
                    <strong>SK Before</strong>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: theme.palette.success.dark
                    }}
                  >
                    <strong>SK After</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {safeKeepingChangePreview.map((item, index) => (
                  <Fragment key={index}>
                    {item.before.map((beforePosition: SafeKeepingPosition, i: number) => {
                      const afterPosition = item.after[i] || {};

                      // Handle pledges that are going to be "squished"
                      const updatedBeforePosition = !item.after[i]
                        ? { ...beforePosition, pledgedAmount: 'Combined' }
                        : beforePosition;

                      return (
                        <TableRow key={i}>
                          <TableCell align="left">{`${item.cusip || 'N/A'}`}</TableCell>
                          <TableCell align="left">{`${updatedBeforePosition.pledgee?.pledgeeName || 'N/A'}`}</TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color:
                                updatedBeforePosition.pledgedAmount === 'Combined'
                                  ? theme.palette.error.dark
                                  : 'inherit'
                            }}
                          >
                            {updatedBeforePosition.pledgedAmount === 'Combined'
                              ? 'Combined Into Single Pledge Above' // Render string for squished pledges into parent
                              : fCurrency(afterPosition.pledgedAmount || updatedBeforePosition.pledgedAmount)}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: theme.palette.error.dark
                            }}
                          >{`${updatedBeforePosition.safekeepingCode}`}</TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: theme.palette.success.dark
                            }}
                          >
                            {toSkCode && safeKeepingCodeToNameMap[toSkCode.safeKeepingCode]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationModalOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={saveBulkSkChange} variant="contained" color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
}
