import React from 'react';
import { Box, Card, Divider, Typography } from '@mui/material';
import { fDateStandard } from '@bakerweb/utils';

type ActivityData = {
  date: Date;
  description: string;
};

interface PortfolioActivityProps {
  activity?: ActivityData[];
}

export function PortfolioActivity({ activity }: PortfolioActivityProps) {
  return (
    <Card>
      <Box padding={2}>
        <Typography variant="subtitle1">Portfolio Activity</Typography>
        <Box mb={1}>
          <Divider />
        </Box>
        {activity && activity.length > 0 ? (
          activity.slice(0, 10).map(({ date, description }) => (
            <Box display="flex" flexDirection="row">
              <Typography variant="body1" mr={2}>
                {fDateStandard(date)}
              </Typography>
              <Typography variant="body1">{description}</Typography>
            </Box>
          ))
        ) : (
          <Box>
            <Typography variant="body1">No recent activity.</Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
}
