import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { clientHttp } from '../client.http';
import { MailDataDto } from '@bakerweb/dtos';
import { fetchUserAttributes } from 'aws-amplify/auth';

type Payload = {
  emailTo: string;
  portfolioCode: string;
};

export const useSendBbaRequestUpdateEmail = () => {
  const sendEmail = useCallback(async (data: Payload) => {
    try {
      const userAttributes = await fetchUserAttributes();
      const userEmail = userAttributes.email;

      if (!userEmail) {
        throw new Error('Email not found in token');
      }

      const mailDataDto: MailDataDto = {
        to: data.emailTo,
        subject: `Full BBA Update Request - (${data.portfolioCode})`,
        from: 'no-reply@gobakersolutions.com',
        cc: ['bbadepartment@gobaker.com'],
        template: 'request-bba-update',
        context: {
          portfolioCode: data.portfolioCode,
          userEmail
        }
      };

      const urlFragment = '/mail';
      await clientHttp(urlFragment, {
        method: 'POST',
        data: mailDataDto
      });

      toast.success('Email sent successfully!');
    } catch (error) {
      toast.error('Failed to send email.');
      console.error(error);
    }
  }, []);

  return {
    sendEmail
  };
};
