import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { clientHttp } from '../client.http';

type Payload = {
  positions: { positionId: string }[];
  emailTo: string;
  notes: string;
  portfolioCode: string;
};

export const useSendSecuritiesEmail = () => {
  const sendEmail = useCallback(async (data: Payload) => {
    try {
      const urlFragment = '/position/email';
      await clientHttp(urlFragment, {
        method: 'POST',
        data
      });
      toast.success('Email sent successfully!');
    } catch (error) {
      toast.error('Failed to send email.');
      console.error(error);
    }
  }, []);

  return {
    sendEmail
  };
};
