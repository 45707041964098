import { Authenticator, Button, Heading, Text, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { type UseAuthenticator } from '@aws-amplify/ui-react-core';
import { useUserPermissions } from '@bakerweb/client-services';
import { BakerSoftwareSolutionsLogo } from '@bakerweb/components';
import { ProgressBarStyle, RtlLayout } from '@bakerweb/layouts';

import { GlobalStyles, ThemeConfig, ThemePrimaryColor } from '@bakerweb/theme';
import { Stack } from '@mui/material';

import { type AuthUser } from 'aws-amplify/auth';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Router from '../routes';

type AppProps = {
  signOut?: UseAuthenticator['signOut'];
  user?: AuthUser;
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.xl}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <BakerSoftwareSolutionsLogo sx={{ width: 175, height: 90 }} />
        </Stack>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>&copy;{new Date().getFullYear()} The Baker Group</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3} color={'#0063ad'}></Heading>;
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button onClick={toForgotPassword} size="small" paddingBottom={25} variation={'link'} color={'#0063ad'}>
            Reset Password
          </Button>
        </View>
      );
    }
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button onClick={toSignIn} size="small">
            Back to Sign In
          </Button>
        </View>
      );
    }
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}></Heading>;
    },
    Footer() {
      return <Text></Text>;
    }
  },
  SetupTotp: {
    Header() {
      return <Heading level={4}>Scan QR code with your authenticator app or copy code below.</Heading>;
    },
    Footer() {
      return <Text></Text>;
    }
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}></Heading>;
    },
    Footer() {
      return <Text></Text>;
    }
  },
  ResetPassword: {
    Header() {
      return <Heading level={3}>Reset Password</Heading>;
    },
    Footer() {
      return <Text></Text>;
    }
  },
  ConfirmResetPassword: {
    Header() {
      return <Heading level={3}>Reset Password</Heading>;
    },
    Footer() {
      return <Text></Text>;
    }
  }
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: 'Email'
    }
  },
  signUp: {
    password: {
      labelHidden: false,
      label: 'Password',
      placeholder: 'Enter Password',
      isRequired: false,
      order: 2
    },
    confirm_password: {
      labelHidden: false,
      label: 'Confirm Password',
      order: 1
    }
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: 'Enter your password'
    }
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: 'Enter your email'
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter Emailed Verification Code',
      label: 'Enter Emailed Verification Code',
      isRequired: true
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Enter your new password'
    }
  },
  setupTotp: {
    QR: {
      totpIssuer: 'The Baker Group'
    },
    confirmation_code: {
      labelHidden: false,
      label: 'Enter Authenticator Code',
      placeholder: 'Enter your authenticator code',
      isRequired: false
    }
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'Enter Authenticator Code',
      placeholder: 'Enter your authenticator code',
      isRequired: false
    }
  }
};

const AppContent = () => {
  const { error } = useUserPermissions();

  useEffect(() => {
    if (error) {
      console.error('There was an error retrieving user permissions.');
    }
  }, [error]);

  return (
    <RtlLayout>
      <GlobalStyles />
      <ProgressBarStyle />
      <Router />
    </RtlLayout>
  );
};

export const App: React.FC<AppProps> = ({ signOut, user }) => (
  <ThemeConfig>
    <ThemePrimaryColor>
      <Authenticator formFields={formFields} components={components} hideSignUp={true}>
        <AppContent />
      </Authenticator>
    </ThemePrimaryColor>
    <ToastContainer
      position="bottom-center"
      theme="dark"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
    />
  </ThemeConfig>
);
